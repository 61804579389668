function buildSort(sortDirection, sortField) {
    if (sortDirection && sortField) {
        return `&sortField=${sortField}&sortDirection=${sortDirection}`;
    }
    return "";
}

function buildFrom(current, resultsPerPage) {
    if (!current || !resultsPerPage) return "";
    const f = (current - 1) * resultsPerPage;
    return `&from=${f}`;
}

function getTermFilter(filter) {
    return `&${filter.field}=${encodeURIComponent(filter.values.join(","))}`
  }

function buildRequestFilter(filters) {
    if (!filters || filters.length === 0) return "";

    filters = filters.reduce((acc, filter) => {
        if (["language", "contentType", "topic", "publication", "publishedAfter", "publishedBefore", "pubDate", "edition"].includes(filter.field)) {
          return acc + getTermFilter(filter);
        }
        return acc;
      }, "");

      return `&${filters}`;
}

export default function buildRequest(state) {
    const {
      current,
      filters,
      resultsPerPage,
      searchTerm,
      sortDirection,
      sortField
    } = state;

    const sort = buildSort(sortDirection, sortField);
    const size = `&size=${resultsPerPage}`;
    const from = buildFrom(current, resultsPerPage);
    const queryTerm = searchTerm ? searchTerm : "";
    const filter = buildRequestFilter(filters);

    const params = `?q=${queryTerm}${sort}${size}${from}${filter}`;
    return params;
  }
