import PropTypes from "prop-types";
import React from "react";
import Moment from 'moment';

import { appendClassName } from '@elastic/react-search-ui-views/lib/view-helpers';

import {
  IconArticle,
  IconJournalism,
  IconDocument,
  IconPodcast,
  IconVideo,
  IconBook
} from "../components/icons";


const mapIcons = {
  ARTICLE: <IconArticle />,
  JOURNALISM: <IconJournalism />,
  DOCUMENT: <IconDocument />,
  PODCAST: <IconPodcast />,
  VIDEO: <IconVideo />,
  BOOK: <IconBook />
};


function formatDate(dateString) {
    Moment.locale('en');
    if (dateString === null) { return ""; }
    return Moment(dateString).format('MMMM DD, YYYY')
}

function Result({
  className,
  result,
  onClickLink,
  titleField,
  urlField,
  setFilter,
  setSearchTerm,
  ...rest
}) {
  const title = result["title"].raw;
  const url = result["url"].raw
  const paywalled = result["paywalled"] && result["paywalled"].raw ? " [$]" : "";
  
  const contentType = result["contentType"].raw
  const publication = result["publication"].raw
  const pubDate = formatDate(result["pubDate"].raw)
  const topic = result["topic"].raw
  
  // const language = result["language"].raw

  // styles - they maybe should not be here?
  const bodyStyle = { display: "block" };
  const contentTypeStyle = { 'fontWeight': "bold" }

  return (
    <li  className={appendClassName("sui-result", className)} {...rest}>

      <div className="sui-result_author_col">
        <span className="sui-result__authors">{ result["authors"].raw } {(result["etAl"].raw ? <i>et al.</i> : "")} {result["authors"].raw && result["publication"].raw && ", "}</span>

        <button className="sui-result__publication" onClick={() => {setSearchTerm(''); setFilter("publication", publication, "any");}}>{publication}</button>

      </div>


      <div style={bodyStyle} className="sui-result__body">
       <span className="type_icon">{mapIcons[contentType]}</span> 
      <a
            className="sui-result__title sui-result__title-link"
            dangerouslySetInnerHTML={{ __html: title + paywalled }}
            href={url}
            onClick={onClickLink}
            target="_blank"
            rel="noopener noreferrer"
        />

        {/* Karen Greenberg, Robert Pollin | KPFA */}
        <div className = "sui-result__untertitle">
            <span>
            {/* <span className="sui-result__publication">{publication}</span> */}
            </span>
        </div>

        {/* Podcast */}
        {/* <div className="sui-result__description">
          <button style={contentTypeStyle} className="sui-result__contentType" onClick={() => {setSearchTerm(''); setFilter("contentType", contentType, "any")}}>{contentType}</button>
        </div> */}

        {/* March 16, 2020      Neoliberalistm*/}
        {/* <div className="sui-result__description">
            <span style={{'width': '100%' }}>
              <span className="sui-result__pubDate">{ pubDate }</span> 
              <button style={{'float': 'right'}} className="sui-result__language" onClick={() => {setSearchTerm(''); setFilter("topic", topic, "any"); }}>{topic}</button> 
            </span>
        </div> */}
        {/* <ul className="sui-result__details">
          {Object.entries(fields).map(([fieldName, fieldValue]) => (
            <li key={fieldName}>
              <span className="sui-result__key">{fieldName}</span>{" "}
              <span
                className="sui-result__value"
                dangerouslySetInnerHTML={{ __html: fieldValue }}
              />
            </li>
          ))}
        </ul> */}
      </div>
    </li>
  );
}

Result.propTypes = {
  result: PropTypes.object.isRequired,
  onClickLink: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
  titleField: PropTypes.string,
  urlField: PropTypes.string,
};

export default Result;