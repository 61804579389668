import React from "react";
import { withSearch } from "@elastic/react-search-ui";

function ClearFilters({ filters, clearFilters }) {
   if (filters.length===0) return null;
   const filterS = filters.length > 1 ? "filters" : "filter";
  return (
    <div className="clearFilters_outer_div">
      <button className="clearFilters_button" onClick={() => clearFilters()}>
        Clear {filters.length} {filterS}
      </button>
    </div>
  );
}

export default withSearch(({ filters, clearFilters }) => ({
  filters,
  clearFilters
}))(ClearFilters);