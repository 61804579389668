import React from "react";

export const IconWebsite = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.47997 0 0 4.47997 0 10C0 15.52 4.47997 20 10 20C15.52 20 20 15.52 20 10C20 4.47997 15.52 0 10 0ZM9.75603 0.495427V4.87783H6.08983C6.85969 2.38881 8.18312 0.658188 9.75603 0.494991V0.495427ZM10.2438 0.495427C11.8168 0.658585 13.1401 2.38902 13.91 4.87783H10.2438V0.495427ZM8.16308 0.663179C7.08817 1.47163 6.18665 2.97119 5.60207 4.87803H1.98164C3.35629 2.7279 5.5687 1.16949 8.16326 0.663179H8.16308ZM11.837 0.663179C14.4313 1.16949 16.6436 2.7279 18.0186 4.87803H14.3981C13.8136 2.97119 12.912 1.47145 11.8371 0.663179H11.837ZM1.69192 5.36614H5.45712C5.10341 6.68247 4.89771 8.16993 4.8778 9.74855H0.495401C0.536913 8.16087 0.965305 6.66762 1.69213 5.36614H1.69192ZM5.95254 5.36614H9.75585V9.74855H5.37345C5.39353 8.17211 5.5982 6.67799 5.95276 5.36614H5.95254ZM10.2436 5.36614H14.0469C14.4013 6.67821 14.6062 8.17233 14.6262 9.74855H10.2438L10.2436 5.36614ZM14.5424 5.36614H18.3076C19.0344 6.66784 19.4627 8.16109 19.5043 9.74855H15.1219C15.102 8.16971 14.8963 6.68226 14.5426 5.36614H14.5424ZM0.495314 10.2363H4.87772C4.89677 11.8211 5.10944 13.3137 5.46469 14.6344H1.69183C0.9623 13.3282 0.534251 11.8305 0.495096 10.2363H0.495314ZM5.37336 10.2363H9.75577V14.6344H5.95246C5.59586 13.3169 5.39222 11.8208 5.37314 10.2363H5.37336ZM10.2436 10.2363H14.626C14.6069 11.8206 14.4033 13.3166 14.0467 14.6344H10.2434L10.2436 10.2363ZM15.1216 10.2363H19.504C19.4649 11.8303 19.0368 13.328 18.3073 14.6344H14.5344C14.8897 13.3139 15.1023 11.8213 15.1214 10.2363H15.1216ZM1.98922 15.1222H5.60198C6.18538 17.0255 7.08316 18.5201 8.15533 19.3296C5.56674 18.8223 3.36265 17.2673 1.98939 15.1222H1.98922ZM6.08974 15.1222H9.75594V19.4972C8.18443 19.3343 6.85995 17.6074 6.08974 15.1222ZM10.2437 15.1222H13.9099C13.1399 17.6075 11.8154 19.3342 10.2437 19.4972V15.1222ZM14.3977 15.1222H18.0104C16.6368 17.2673 14.4329 18.8221 11.8445 19.3296C12.9167 18.5201 13.8144 17.0255 14.3978 15.1222H14.3977Z"
        fill="black"
      />
    </svg>
  );
};

export const IconTwitter = () => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.94491 11.6799C3.40954 11.2819 2.98781 10.7539 2.7177 10.149C3.22213 10.1934 3.73166 10.147 4.22183 10.0108L4.20422 9.52481C3.32333 9.34754 2.53188 8.86846 1.96639 8.17018C1.4868 7.57798 1.19522 6.8596 1.12361 6.10693C1.61107 6.31807 2.13372 6.44015 2.66667 6.46608L3.56021 6.50954L2.81866 6.00914C2.00799 5.4621 1.4346 4.6283 1.21388 3.67555C1.02071 2.8417 1.11021 1.97091 1.46239 1.1982C2.47027 2.36194 3.69534 3.31989 5.07031 4.01775C6.55835 4.773 8.1887 5.2067 9.85532 5.29065L10.1866 5.30733L10.1114 4.98429C9.91891 4.15739 10.0044 3.29011 10.3548 2.51678C10.7051 1.74345 11.3007 1.1072 12.0493 0.706607C12.7978 0.306021 13.6575 0.163457 14.4953 0.300967C15.3331 0.438476 16.1022 0.848381 16.6834 1.46723L16.7784 1.56835L16.9144 1.54127C17.6239 1.40007 18.3117 1.17017 18.962 0.857682C18.629 1.47505 18.1327 1.99482 17.5218 2.35662L17.679 2.81995C18.2264 2.75431 18.7653 2.63535 19.2882 2.46515C18.8578 2.98508 18.3622 3.44899 17.8128 3.845L17.7031 3.9241L17.7093 4.05923C17.805 6.15137 17.3237 8.22957 16.3179 10.0666C15.3122 11.9036 13.8207 13.4288 12.0066 14.4753C10.1925 15.5218 8.12555 16.0494 6.03179 16.0003C4.29222 15.9596 2.58979 15.5221 1.0524 14.727C1.81881 14.7281 2.58353 14.6249 3.32525 14.4189C4.41729 14.1157 5.43717 13.5958 6.3242 12.8904L6.86556 12.4598L6.17402 12.4448C5.36962 12.4273 4.5906 12.16 3.94491 11.6799Z"
        stroke="black"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const IconArticle = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5757 26.6426C20.7993 26.6426 26.6553 20.7866 26.6553 13.563C26.6553 6.33933 20.7993 0.483398 13.5757 0.483398C6.35203 0.483398 0.496094 6.33933 0.496094 13.563C0.496094 20.7866 6.35203 26.6426 13.5757 26.6426Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.78809 21.4277H19.3631V8.29401L16.8071 5.69922H7.78809V21.4277Z"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8076 5.7832V8.321H19.3625"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72461 16.1846H17.4264"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72461 8.32031H14.1337"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72461 18.8066H17.4264"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72461 10.9424H17.4264"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72461 13.5635H17.4264"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconPodcast = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4984 26.6129C20.7214 26.6129 26.5768 20.7575 26.5768 13.5345C26.5768 6.31148 20.7214 0.456055 13.4984 0.456055C6.27534 0.456055 0.419922 6.31148 0.419922 13.5345C0.419922 20.7575 6.27534 26.6129 13.4984 26.6129Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.05371 11.8164V13.3163C8.05371 16.3203 10.489 18.7569 13.4943 18.7569C16.4996 18.7569 18.9349 16.3216 18.9349 13.3163V11.8164"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.4814 21.7047V18.752"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.5059 5.36035C11.9022 5.36035 10.6016 6.661 10.6016 8.26467V13.2127C10.6016 14.8164 11.9022 16.117 13.5059 16.117C15.1096 16.117 16.4102 14.8164 16.4102 13.2127V8.26467C16.4102 6.661 15.1096 5.36035 13.5059 5.36035Z"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export const IconVideo = () => {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4995 26.8018C20.7232 26.8018 26.5791 20.9458 26.5791 13.7222C26.5791 6.49851 20.7232 0.642578 13.4995 0.642578C6.27585 0.642578 0.419922 6.49851 0.419922 13.7222C0.419922 20.9458 6.27585 26.8018 13.4995 26.8018Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.4899 6.80762H7.50656C6.62165 6.80762 5.9043 7.53083 5.9043 8.42296V18.6448C5.9043 19.5369 6.62165 20.2602 7.50656 20.2602H19.4899C20.3748 20.2602 21.0921 19.5369 21.0921 18.6448V8.42296C21.0921 7.53083 20.3748 6.80762 19.4899 6.80762Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.9043 10.9209H21.0921"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.33887 10.8565L11.3541 6.80762"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.9209 10.8565L15.937 6.80762"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.3965 10.8565L20.2315 6.99023"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.0218 15.2099L12.5168 12.9734C12.25 12.803 11.9023 12.9969 11.9023 13.3151V17.788C11.9023 18.1062 12.25 18.2992 12.5168 18.1296L16.0218 15.8932C16.2701 15.7349 16.2701 15.3682 16.0218 15.2099Z"
        fill="#0000FF"
      />
    </svg>
  );
};

export const IconDocument = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5601 26.5977C20.8263 26.5977 26.7168 20.7072 26.7168 13.4409C26.7168 6.17465 20.8263 0.28418 13.5601 0.28418C6.29379 0.28418 0.40332 6.17465 0.40332 13.4409C0.40332 20.7072 6.29379 26.5977 13.5601 26.5977Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.3305 9.42969H5.79492V20.2398H21.3305V9.42969Z"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7108 9.431H5.79492V6.63965H14.0163L15.7108 9.431Z"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36523 12.0361H15.8571"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36523 14.0234H13.3591"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconJournalism = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6382 27.2842C20.9044 27.2842 26.7949 21.3937 26.7949 14.1274C26.7949 6.86118 20.9044 0.970703 13.6382 0.970703C6.37192 0.970703 0.481445 6.86118 0.481445 14.1274C0.481445 21.3937 6.37192 27.2842 13.6382 27.2842Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.31445 22.0732H17.9625V23.5865H9.31445V22.0732Z"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6384 20.7912H16.8814L19.0435 13.2242C18.2218 12.3807 17.119 11.0185 16.2545 9.11668C15.2163 6.82458 14.9788 4.74902 14.936 3.49512H14.0715V13.1377C14.3308 13.2888 14.5037 13.57 14.5037 13.8729C14.5037 14.3488 14.1143 14.7374 13.6393 14.7374C13.1642 14.7374 12.7748 14.348 12.7748 13.8729C12.7748 13.549 12.9477 13.2678 13.207 13.1377V3.49512H12.3417C12.2981 4.74902 12.0606 6.82458 11.0232 9.11668C10.1579 11.0194 9.03324 12.3815 8.2334 13.2242L10.3954 20.7912H13.6384Z"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconBook = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4995 26.6426C20.7232 26.6426 26.5791 20.7866 26.5791 13.563C26.5791 6.33933 20.7232 0.483398 13.4995 0.483398C6.27585 0.483398 0.419922 6.33933 0.419922 13.563C0.419922 20.7866 6.27585 26.6426 13.4995 26.6426Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.4993 20.9108L6.29653 17.9148C6.24778 17.8938 6.21484 17.8463 6.21484 17.7936V6.34855C6.21484 6.25501 6.31102 6.19045 6.39666 6.22734L13.498 9.18249V20.9108H13.4993Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.501 20.9108L20.7037 17.9135C20.7525 17.8925 20.7854 17.845 20.7854 17.7923V6.34855C20.7854 6.25501 20.6892 6.19045 20.6036 6.22734L13.5023 9.18249V20.9108H13.501Z"
        fill="white"
        stroke="#0000FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
