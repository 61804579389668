import React from "react";

export default {
    backend: {
        host: "https://api.the-syllabus.com",
        index: "api/v1"
    },
    header: {
        logo: null,
        title: "The Syllabus",
        intro: <span>{"Browse and search a wide range of analysis and commentary - across text, video and audio in multiple languages. Powered by the curation technology - part-algorithmic, part-human - developed by"}{" "}<a href="https://the-syllabus.com/">{"The Syllabus."}</a></span>,
    }
};
