import PropTypes from "prop-types";
import { Component } from "react";
import RetractableSafeMultiCheckboxFacet from "../views/RetractableSafeMultiCheckboxFacet";
import { helpers } from "@elastic/search-ui";

import { Facet, Filter, FilterType } from "@elastic/react-search-ui/lib/types";
import { accentFold } from "@elastic/react-search-ui/lib/helpers";
import { withSearch } from "@elastic/react-search-ui";

const { markSelectedFacetValuesFromFilters, findFilterValues } = helpers;

export class FacetContainer extends Component {
  static propTypes = {
    // Props
    className: PropTypes.string,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    filterType: FilterType,
    show: PropTypes.number,
    view: PropTypes.func,
    isFilterable: PropTypes.bool,
    // State
    filters: PropTypes.arrayOf(Filter).isRequired,
    facets: PropTypes.objectOf(PropTypes.arrayOf(Facet)).isRequired,
    // Actions
    addFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    a11yNotify: PropTypes.func.isRequired
  };

  static defaultProps = {
    filterType: "all",
    isFilterable: false
  };

  constructor({ show = 5 }) {
    super();
    this.state = {
      defaultShow: show,
      more: show,
      searchTerm: "",
      open: false,
    };
  }

  handleClickMore = totalOptions => {
    this.setState(({ more }) => {
      let visibleOptionsCount = more + 10;
      const showingAll = visibleOptionsCount >= totalOptions;
      if (showingAll) visibleOptionsCount = totalOptions;

      this.props.a11yNotify("moreFilters", { visibleOptionsCount, showingAll });

      return { more: visibleOptionsCount };
    });
  };

  handleClickLess = defaultOptions => {
    this.setState(({ more }) => {
      let visibleOptionsCount = more - 10;
      const showingAll = visibleOptionsCount <= defaultOptions;
      if (showingAll) visibleOptionsCount = defaultOptions;

      // potentially figure this out
    //   this.props.a11yNotify("moreFilters", { visibleOptionsCount, showingAll });

      return { more: visibleOptionsCount };
    });
  };

  handleFacetSearch = searchTerm => {
    this.setState({ searchTerm });
  };

  handleOpen = () =>{
    this.setState( {open: !this.state.open} )
  }


  render() {
    const { more, defaultShow, searchTerm } = this.state;
    const {
      addFilter,
      className,
      facets,
      field,
      filterType,
      filters,
      label,
      removeFilter,
      setFilter,
      view,
      isFilterable,
      // eslint-disable-next-line no-unused-vars
      a11yNotify,
      ...rest
    } = this.props;

    const facetsForField = facets[field];

    if (!facetsForField) return null;

    // By using `[0]`, we are currently assuming only 1 facet per field. This will likely be enforced
    // in future version, so instead of an array, there will only be one facet allowed per field.
    const facet = facetsForField[0];

    const filterValues = findFilterValues(filters, field, filterType);
    const facetValues = markSelectedFacetValuesFromFilters(facet, filters, field, filterType).data;
    const selectedValues = facetValues.filter(fv => fv.selected).map(fv => fv.value);
    const selectedFacetValuesHash = facetValues.filter(fv => fv.selected).reduce((map, obj) => {map[obj.value] = obj.selected; return map}, {});
    const missingFilterValues = filterValues.filter(fv => !selectedFacetValuesHash[fv]).map(fv => { return {"value": fv, "count": "few", "selected": true}})

    // we first generate the complete list of facets to show, 
    // including the ones found in filtering settings but not in server faceting
    let completeFacetSet = [...facetValues, ...missingFilterValues];

    // we then filter these by the filtering search term
    if (searchTerm.trim()) {
      completeFacetSet = completeFacetSet.filter(option =>
        accentFold(option.value)
          .toLowerCase()
          .includes(accentFold(searchTerm).toLowerCase())
      );
    }

    // and finally make sure that all selected options are always shown
    const visibleValues = completeFacetSet.slice(0, more);
    const facetValuesAfterCutoff = completeFacetSet.slice(more).filter(fv => fv.selected);
    const completeVisibleValues  = [...visibleValues, ...facetValuesAfterCutoff]

    const View = view || RetractableSafeMultiCheckboxFacet;

    return View({
      className,
      label: label,
      onMoreClick: this.handleClickMore.bind(this, facetValues.length),
      onLessClick: this.handleClickLess.bind(this, defaultShow),
      onRemove: value => {
        removeFilter(field, value, filterType);
      },
      onChange: value => {
        setFilter(field, value, filterType);
      },
      onSelect: value => {
        addFilter(field, value, filterType);
      },
      options: completeVisibleValues,
      showMore: completeFacetSet.length > more,
      showLess: more > defaultShow,
      values: selectedValues,
      showSearch: isFilterable,
      open: this.handleOpen,
      openState: this.state.open,
      onSearch: value => {
        this.handleFacetSearch(value);
      },
      searchPlaceholder: `Filter ${field}`,
      ...rest
    });
  }
}

export default withSearch(
  ({ filters, facets, addFilter, removeFilter, setFilter, a11yNotify }) => ({
    filters,
    facets,
    addFilter,
    removeFilter,
    setFilter,
    a11yNotify
  })
)(FacetContainer);