import Config from "./config";

export default async function runRequest(requestParams, bearer) {
  console.log(`token: ${bearer}}]`);
  const response = await fetch(
    `${Config.backend.host}/${Config.backend.index}/search${requestParams}`,
    {
      method: "GET",
      headers: { "content-type": "application/json", Authorization: bearer }
    }
  );
  if (response.status >= 400 && response.status < 500) {
    window.location = "https://cabinet.the-syllabus.com?from=archive";
  }
  return response.json();
}
