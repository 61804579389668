import PropTypes from "prop-types";
import React, { useState } from 'react';

import SafeFacetValue from "./types/SafeFacetValue";
import { appendClassName, getFilterValueDisplay } from "@elastic/react-search-ui-views/lib/view-helpers";
import { MultiCheckboxFacet} from '@elastic/react-search-ui-views';
import { getPrettyName, getLabelName } from '../common';

import {
  IconArticle,
  IconJournalism,
  IconDocument,
  IconPodcast,
  IconVideo,
  IconBook
} from "../components/icons";


const mapIcons = {
  ARTICLE: <IconArticle />,
  JOURNALISM: <IconJournalism />,
  DOCUMENT: <IconDocument />,
  PODCAST: <IconPodcast />,
  VIDEO: <IconVideo />,
  BOOK: <IconBook />
};

const  RetractableSafeMultiCheckboxFacet = ({
  className,
  label,
  onMoreClick,
  onLessClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showLess,
  showSearch,
  onSearch,
  searchPlaceholder,
  open,
  openState,
}) => {

  return (
    <fieldset className={appendClassName("sui-facet", className)}>

      <legend onClick={()=> open()} className={openState ? ("sui-facet__title open"): ("sui-facet__title")}>{getLabelName(label)}</legend>

      <div className={openState ? ("facet_container open"): ("facet_container")}>
      {showSearch && (
        <div className="sui-facet-search">
          <input
            className="sui-facet-search__text-input"
            type="search"
            placeholder={searchPlaceholder || "Search"}
            onChange={e => {
              onSearch(e.target.value);
            }}
          />
        </div>
      )}

      <div className="sui-multi-checkbox-facet">
        {options.length < 1 && <div>No matching options</div>}
        {options.map(option => {
          const checked = option.selected;

          return (
            <label
              key={`${getFilterValueDisplay(option.value)}`}
              htmlFor={`example_facet_${label}${getFilterValueDisplay(
                option.value
              )}`}
              className="sui-multi-checkbox-facet__option-label"
            > 
              {label == "Content Type" ? (mapIcons[option.value]):("") }

              <div className="sui-multi-checkbox-facet__option-input-wrapper">
                <input
                  id={`example_facet_${label}${getFilterValueDisplay(
                    option.value
                  )}`}
                  type="checkbox"
                  className="sui-multi-checkbox-facet__checkbox"
                  checked={checked}
                  onChange={() =>
                    checked ? onRemove(option.value) : onSelect(option.value)
                  }
                />
                <span className={(checked ? (`checked sui-multi-checkbox-facet__input-text`): (`sui-multi-checkbox-facet__input-text`))}>
                {getPrettyName(label, option)}
                  {/* {getFilterValueDisplay(option.label || option.value)} */}
                </span>
              </div>
              <span className="sui-multi-checkbox-facet__option-count">
                { option.count.toLocaleString("en") }
              </span>
            </label>
          );
        })}
      </div>

      {showMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={onMoreClick}
          aria-label="Show more options"
        >
          + More
        </button>
      )}

      {showLess && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={onLessClick}
          aria-label="Show less options"
        >
          - Less
        </button>
      )}
      </div>

    </fieldset>
  );
}

RetractableSafeMultiCheckboxFacet.propTypes = {
  ...MultiCheckboxFacet.propTypes,
  options: PropTypes.arrayOf(SafeFacetValue).isRequired,
  showLess: PropTypes.bool.isRequired,
  onLessClick: PropTypes.func.isRequired
};

export default RetractableSafeMultiCheckboxFacet;