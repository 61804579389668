function capitalize (s) {
  const l = s.toLowerCase()
  return l.charAt(0).toUpperCase() + l.slice(1)
}

export function getLabelName(label) {
  switch(label) {
    case 'topic': return 'Series'
    default: return label
  }
}

export function getPrettyName(label, option) {
  switch(label) {
    case 'Edition':
      return option.label
    case 'Language':
    case 'Content Type':
      return capitalize(option.value)
    case 'topic':
      switch(option.value) {
        case 'S0': return 'Best of Social Justice'
        case 'S3': return 'Best of Arts & Culture'
        case 'S4': return 'Best of Political Economy'
        case 'S5': return 'Best of World'
        case 'S6': return 'Best of Technology'

        case 'ARTICLE': return 'Best of Academic Articles'
        case 'JOURNALISM': return 'Best of Journalism'
        case 'PODCAST': return 'Best of Podcasts'
        case 'VIDEO': return 'Best of Videos'
        case 'B0': return 'All Highlights'

        case 'L1': return 'Best of French'
        case 'L2': return 'Best of German'
        case 'L3': return 'Best of Spanish'
        case 'L4': return 'Best of Italian'
        case 'L5': return 'Best of Portuguese'
        default: return option.value
      }
    default:
      return option.value
  }
}

export function getToken() {
  if (localStorage['vuex']) {
    const vueStore = JSON.parse(localStorage['vuex'])
    const { authToken } = vueStore
    return authToken
  }
  return null
}
