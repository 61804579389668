import PropTypes from "prop-types";
import FilterValue from "@elastic/react-search-ui-views/lib/types/FilterValue";

export default PropTypes.shape({
  // Number of results for this filter
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  // Filter to apply if selected
  value: FilterValue.isRequired,
  // Whether or not this facet value is selected
  selected: PropTypes.bool
});