import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Footer from "./Footer";

class AppWithTitle extends React.PureComponent {
  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
          </Routes>

          <div className="sui-footer" >
            <img src="/img/logo-white.svg" />
            <span className="sui-footer-span" >Copyright ⓒ 2020, The Syllabus, All Rights Reserved</span>
            </div>
        </BrowserRouter>
      </>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<AppWithTitle />, rootElement);
