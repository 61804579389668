import React from "react";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import buildRequest from "./buildRequest";
import runRequest from "./runRequest";
import buildState from "./buildState";
import Result from "./views/Result";
import SingleSelectOrderedFacet from "./views/SingleSelectOrderedFacet";
import FilteredPagingInfo from "./views/FilteredPagingInfo";
import SafeFacet from "./containers/SafeFacet";
import ClearFilters from "./components/ClearFilters";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";

import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./styles.css";

import Config from "./config";

const SORT_OPTIONS = [
  {
    name: "RELEVANCE",
    value: "",
    direction: ""
  },
  {
    name: "PUBLICATION DATE ↓",
    value: "pubDate",
    direction: "asc"
  },
  {
    name: "PUBLICATION DATE ↑",
    value: "pubDate",
    direction: "desc"
  }
];

/* const config = {
  alwaysSearchOnInitialLoad: true,
  initialState: {
    resultsPerPage: 30
  },
  onSearch: async (state) => {
    const { resultsPerPage } = state;
    const requestParams = buildRequest(state);
    const response = await runRequest(requestParams);
    return buildState(response, resultsPerPage);
  }
}; */

const newheader = (
  <>
    <header>
      <div className="header_container header_container--cabinet">
        <div className="header_logo">
          <a href="https://www.the-syllabus.com/cabinet">
            <img src="/img/logo-white.svg" />
          </a>
        </div>
        <div className="header_link_container">
          <p>CABINET</p>

          {/* <div className="header_link">
            <div className="btn btn-reverse">Your Subscribtion</div>
          </div> */}
        </div>
      </div>
    </header>
    <SearchBox
      autocompleteSuggestions={false}
      shouldClearFilters={false}
      inputView={({ getInputProps }) => (
        <>
          <div className="sui-search-box__wrapper">
            <input
              {...getInputProps({
                placeholder: "Search for a term..."
              })}
            />
          </div>
        </>
      )}
    />
  </>
);

export default function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenForSearch = searchParams.get("srcMmnt");
  const [cookies, setCookie] = useCookies(["tokenSearch"]);
  if (!cookies || !cookies.tokenSearch) {
    if(!tokenForSearch) {
      window.location = "https://www.the-syllabus.com/cabinet";
      return
    } 
    setCookie("tokenSearch", `Bearer ${tokenForSearch}`, {
      path: "/",
      maxAge: 864000, // Expires after 1day
      sameSite: true
    });
  }
  const config = {
    // debug: true,
    alwaysSearchOnInitialLoad: true,
    initialState: {
      resultsPerPage: 30,
      sortDirection: "desc",
      sortField: "pubDate"
      // filters: [{"field": "edition", "values": ["latest"], "type": "any"}]
    },
    onSearch: async (state) => {
      const { resultsPerPage } = state;
      const requestParams = buildRequest(state);
      const response = await runRequest(
        requestParams,
        cookies && cookies.tokenSearch
          ? cookies.tokenSearch
          : `Bearer ${tokenForSearch}`
      );
      return buildState(response, resultsPerPage);
    }
  };

  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({
          wasSearched,
          setFilter,
          setSearchTerm,
          filters,
          facets
        }) => ({ wasSearched, setFilter, setSearchTerm, filters, facets })}
      >
        {({ wasSearched, setFilter, setSearchTerm, filters, facets }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={newheader}
                  sideContent={
                    <div>
                      {/* {wasSearched && (
                        <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS} />
                      )} */}
                      <SafeFacet
                        field="edition"
                        label="Edition"
                        filterType="any"
                        isFilterable={false}
                        show={5}
                      />
                      <SafeFacet
                        field="language"
                        label="Language"
                        filterType="any"
                        isFilterable={false}
                        show={6}
                      />
                      <SafeFacet
                        field="contentType"
                        label="Content Type"
                        filterType="any"
                        isFilterable={false}
                        show={99}
                      />
                      <SafeFacet
                        field="topic"
                        label="topic"
                        filterType="any"
                        isFilterable={false}
                      />
                      <SafeFacet
                        field="publication"
                        label="Publication"
                        filterType="any"
                        isFilterable={false}
                      />
                      <Facet
                        field="publishedAfter"
                        label="Published After"
                        show={500}
                        view={SingleSelectOrderedFacet}
                      />
                      <Facet
                        field="publishedBefore"
                        label="Published Before"
                        show={500}
                        view={SingleSelectOrderedFacet}
                      />
                    </div>
                  }
                  bodyContent={
                    <Results
                      titleField="title"
                      urlField="url"
                      shouldTrackClickThrough={false}
                      resultView={(props) => (
                        <Result
                          {...props}
                          setFilter={setFilter}
                          setSearchTerm={setSearchTerm}
                        />
                      )}
                    />
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && (
                        <PagingInfo
                          view={(props) => (
                            <FilteredPagingInfo
                              {...props}
                              filters={filters}
                              facets={facets}
                            />
                          )}
                        />
                      )}
                      {wasSearched && <ClearFilters />}
                      {wasSearched && (
                        <ResultsPerPage options={[30, 50, 100]} />
                      )}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
