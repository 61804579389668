import buildStateFacets from "./buildStateFacets";

function buildTotalPages(resultsPerPage, totalResults) {
  if (!resultsPerPage) return 0;
  if (totalResults === 0) return 1;
  return Math.ceil(totalResults / resultsPerPage);
}

function buildResults(hits) {
    const addEachKeyValueToObject = (acc, [key, value]) => ({
      ...acc,
      [key]: value
    });

    return hits.map(record => {
      return Object.entries(record)
        .map(([fieldName, fieldValue]) => [
          fieldName,
          { raw: fieldValue }
        ])
        .reduce(addEachKeyValueToObject, {});
    });
  }

/*
  Converts an Elasticsearch response to new application state
  When implementing an onSearch Handler in Search UI, the handler needs to convert
  search results into a new application state that Search UI understands.
  For instance, Elasticsearch returns "hits" for search results. This maps to
  the "results" property in application state, which requires a specific format. So this
  file iterates through "hits" and reformats them to "results" that Search UI
  understands.
  We do similar things for facets and totals.
*/
export default function buildState(response, resultsPerPage) {
  const results = buildResults(response.results);
  const totalResults = response.tot;
  const totalPages = buildTotalPages(resultsPerPage, totalResults);
  const facets = buildStateFacets(response.facets);

  return {
    results,
    totalPages,
    totalResults,
    ...(facets && { facets })
    };
}